import React, { Component } from "react";
import { toast } from "react-toastify";
import queryString from "query-string";
import MediaQuery from "react-responsive";
import styled from "styled-components";
import { setRef, getRef } from "../helpers/utils";

// Layout
import Layout from "../components/layout";

// Components
import Hero from "../components/hero";
import Quotes from "../components/quotes";
import Logos from "../components/logos";
import Example from "../components/example";
import Section from "../components/section";
import Testimonials from "../components/testimonials";
import Cta from "../components/cta";
import ModalExample from "../components/modal-example";
import SectionSlider from "../components/section-slider";
import Row from "../components/row";
import Column from "../components/column";
import Video from "../components/video";
import ModalPricingRequest from "../components/modal-pricing";

import Seo from "../components/seo";

// Logos
import hondaLogo from "../images/logos/honda-logo.png";
import toyotaLogo from "../images/logos/logo-toyota.png";
import fordLogo from "../images/logos/logo-ford.svg";
import gmcLogo from "../images/logos/logo-gmc.png";
import vwLogo from "../images/logos/logo-vw.png";

// Images
import wsst2020 from "../images/fb-testimonial.png";
import facebookGroup from "../images/facebook-group.png";

import quickpageExampleVideo from "../images/email.mp4";
import trackVideoSlider from "file-loader!../images/track/t1.vtt";
import trackVideo from "file-loader!../images/track/automotive-cc.vtt";

const SectionSliderContain = styled.section`
  padding: 5rem 1rem 0;
`;

const SectionWorks = styled.section`
  padding: 0 1rem 5rem;

  h2 {
    margin: 0 0 1rem;

    @media (min-width: 64rem) {
      margin: 0 0 2rem;
      text-align: center;
    }
  }

  @media (max-width: 64rem) {
    .Column:first-of-type {
      margin-bottom: 1rem;
    }
  }

  .contain {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 1rem;
  }
`;

export default class Automotive extends Component {
  state = {
    registerUrl: "https://quickpageapp.com/register",
    visible: false,
    pricingVisible: false,
  };

  componentDidMount() {
    const qs = queryString.parse(window.location.search).s;

    if (qs) {
      toast.success(
        "Thank you for your request! Please check your email and or text!😎"
      );
      window.history.replaceState(null, null, "/");
    }
    setRef(this.props.location);

    const ref =
      getRef(this.props.location) ||
      queryString.parse(this.props.location.search).ref;

    if (ref) {
      this.setState({
        registerUrl: `https://quickpageapp.com/register?ref=${ref}`,
      });
    }
  }

  linkClickHandler = (evt) => {
    evt.preventDefault();
    this.setState({ visible: !this.state.visible });
  };

  renderFBLists() {
    return (
      <ul>
        <li>See exactly how other sales pros are winning with Quickpage</li>
        <li>Join weekly webinars to speed up your success</li>
        <li>Participate in drawings and giveaways</li>
        <li>
          Learn tips and techniques from sales leaders across multiple
          industries
        </li>
      </ul>
    );
  }

  renderSectionHow() {
    return (
      <>
        <h4>Some of the ways sales consultants are using Quickpage</h4>
        <br />
        <ul>
          <li>Walkaround videos</li>
          <li>Replying to internet and phone leads</li>
          <li>Setting appointments (70% improvement in show rate)</li>
          <li>Sharing Testimonials</li>
          <li>BDC replies (increasing sales by 25%)</li>
        </ul>
        <br />
      </>
    );
  }

  render() {
    const { registerUrl, visible, pricingVisible } = this.state;
    const quotes = [
      {
        quote:
          "“I've personally used all the major platforms for video follow up and its Quickpage for the win.”",
        name: "Scott Klein",
        company: "Smith Automotive Group",
      },
      {
        quote:
          "“Quickpage is worth 10x the cost. Buy it now and close a deal the first day you use it.”",
        name: "Trevor Riggs",
        company: "Deals on Wheels",
      },
      {
        quote:
          "“A great tool to reach your customers when email and phone calls don't work.”",
        name: "Baron Washington",
        company: "Dennis Sneed Ford",
      },
    ];

    const logos = [hondaLogo, toyotaLogo, fordLogo, gmcLogo, vwLogo];
    return (
      <Layout cssClass="bg-auto" location={this.props.location}>
        <Seo
          title="The quickest, most effective closing tool in Automotive | Quickpage"
          description="Quickpage is a video follow-up tool for sales that's increasing engagement by 200%. Video email and texting is the future of sales."
          keywords="video, email, video email, email video, sales app"
          url="https://kintz.quickpage.io"
        />

        <Hero
          tagline="Fastest growing video follow-up solution in the world"
          heading={`The <span>quickest</span>, most effective closing tool in Automotive`}
          excerpt={`<div><p><span>Quickpage</span> is the fastest growing video follow up solution in the world.<p> <p>Set yourself apart from the competition and deliver the most unique experience your customer has ever seen.</p></div>`}
          video="https://dkgs2aqh5w6kx.cloudfront.net/media/1C3BCE81-BD3D-4057-A9E3-598D10033402/rHDcpnyfXAGefigCY.mp4-web.mp4"
          poster="https://dkgs2aqh5w6kx.cloudfront.net/media/1C3BCE81-BD3D-4057-A9E3-598D10033402/rHDcpnyfXAGefigCY.mp4-poster-00001.png"
          videoControls={true}
          track={trackVideo}
          location={this.props.location}
          gaTrackLabel="Automotive hero"
          cssClass="bg-auto"
          industry="automotive"
        />

        <Quotes quotes={quotes} />

        <Logos logos={logos} />

        <Example
          inline={true}
          Industry="automotive"
          gaTrackLabel="Automotive page"
        />

        <Section
          heading={`Welcome to the future of selling cars.`}
          tagline="The way you follow up with leads will change forever."
          excerpt={`See your customers drive past dozens of dealerships to do business with YOU, the one that sent them a Quickpage.`}
          image={quickpageExampleVideo}
          type="video"
          link="#"
          linkColor="#46A85C"
          linkText="Get a Quickpage example in your hands right now"
          linkOnClick={this.linkClickHandler}
          columnLeftClass="flex-center"
          lists={this.renderSectionHow()}
        />

        <Section
          heading="5 minute learning curve."
          tagline="Look like a tech genius without being one"
          excerpt="Not only is Quickpage the most effective way to grab your customers attention and actually get a reply but it's as simple as sending an email."
          image={wsst2020}
          imgClasses="-max460"
          link="#"
          linkOnClick={(evt) => {
            evt.preventDefault();
            this.setState({ pricingVisible: !pricingVisible });
          }}
          linkColor="#ff0283"
          linkText="Request pricing"
          component={
            <SectionSlider
              link="https://quickpage-assets.s3.amazonaws.com/public/marketing/testimonials/au/"
              number={14}
            />
          }
        />

        <Testimonials
          testimonials={[
            {
              name: "Testimonial Remix",
              company: "Quickpage",
              avatar:
                "https://quickpage-assets.s3.amazonaws.com/marketing/thumbs/thumb-qp.png",
              url:
                "https://quickpage-prod.s3.amazonaws.com/media/E946E252-A882-49BD-D210-64A8E1721AF0/mEmk6cGTSgjgN2tfT.mp4-web.mp4",
              poster:
                "https://quickpage-prod.s3.amazonaws.com/media/E946E252-A882-49BD-D210-64A8E1721AF0/mEmk6cGTSgjgN2tfT.mp4-poster-00001.png",
              caption: trackVideoSlider,
              playVideo: true,
            },
            {
              name: "Testimonial Remix",
              company: "Quickpage",
              avatar:
                "https://quickpage-assets.s3.amazonaws.com/marketing/thumbs/thumb-qp.png",
              url:
                "https://quickpage-prod.s3.amazonaws.com/media/39513134-A3CD-4678-84C4-F3F7E4B34C77/eyoLoLcHZBFe3kbtM.qt-web.mp4",
              poster:
                "https://quickpage-prod.s3.amazonaws.com/media/39513134-A3CD-4678-84C4-F3F7E4B34C77/eyoLoLcHZBFe3kbtM.qt-poster-00001.png",
            },
            {
              name: "Tony White",
              company: "Manager at Steve Hahn Auto Group",
              avatar:
                "https://quickpage-assets.s3.amazonaws.com/marketing/thumbs/thumb-tw.png",
              url:
                "https://quickpage-prod.s3.amazonaws.com/media/7D0AA372-811D-41F8-AE06-E09332BB164F/1.mp4-web.mp4",
              poster:
                "https://quickpage-assets.s3.amazonaws.com/marketing/posters/poster-tw.jpg",
            },

            {
              name: "Chad Ducky Gitchel",
              company: "Sales Consultant at Preferred Ford",
              avatar:
                "https://quickpage-prod.s3-accelerate.amazonaws.com/media/01C55F5F-7DC5-498F-9749-D1CD3AEC195E/ypqPvcpaeJmmR5H9L.jpeg",
              url:
                "https://dburs8kx36lyd.cloudfront.net/media/1626DEEF-FD10-46A4-84D0-CD92BCBF8CA2/5tMfJE3CZywcRBZot.mp4-web.mp4",
              poster:
                "https://dburs8kx36lyd.cloudfront.net/media/1626DEEF-FD10-46A4-84D0-CD92BCBF8CA2/5tMfJE3CZywcRBZot.mp4-poster-00001.png",
            },
            {
              name: "Gary Pollard",
              company: "Training Manager at Bondy's Enterprise Toyota",
              avatar:
                "https://quickpage-assets.s3.amazonaws.com/marketing/thumbs/thumb-gp.png",
              url:
                "https://quickpage-prod.s3.amazonaws.com/media/4AEFB45C-C2A2-4E0A-F2D9-7DC578D95E44/gary-pollart-video.mp4-web.mp4",
              poster:
                "https://quickpage-prod.s3.amazonaws.com/media/4AEFB45C-C2A2-4E0A-F2D9-7DC578D95E44/gary-pollart-video.mp4-poster-00001.png",
            },
          ]}
        />

        <MediaQuery maxWidth={1023}>
          {(matches) => {
            if (matches) {
              return (
                <SectionSliderContain>
                  <SectionSlider
                    link="https://quickpage-assets.s3.amazonaws.com/public/marketing/testimonials/au/"
                    number={14}
                  />
                </SectionSliderContain>
              );
            } else {
              return null;
            }
          }}
        </MediaQuery>

        <Section
          heading={`Exclusive Quickpage <span>Facebook membership.</span>`}
          excerpt="As a subscriber, you will receive access to our private <strong>Stand out Sales</strong> Facebook group full of 1000s of Quickpage users from across the continent sharing never-before-seen follow up strategies."
          lists={this.renderFBLists()}
          image={facebookGroup}
        />

        <SectionWorks>
          <div className="contain">
            <h2>How it works</h2>
            <Row>
              <Column>
                <Video
                  url="https://s3.amazonaws.com/quickpage-prod/media/253C875C-31EB-4DE8-888D-86AE30EB6957/Quickpag1.mp4"
                  poster="https://quickpage-prod.s3.amazonaws.com/media/288B893D-CE02-4D11-8DDB-18A30163BA66/vt.jpg"
                  autoplay={true}
                />
              </Column>
              <Column>
                <Video
                  url="https://dburs8kx36lyd.cloudfront.net/media/DB9B0D88-C0AC-452E-8A7A-1F7FBB14DCC0/r9EKpSuBjXNYkkNRG.mp4-web.mp4"
                  poster="https://dburs8kx36lyd.cloudfront.net/media/DB9B0D88-C0AC-452E-8A7A-1F7FBB14DCC0/r9EKpSuBjXNYkkNRG.mp4-poster-00001.png"
                  autoplay={true}
                />
              </Column>
            </Row>
          </div>
        </SectionWorks>

        <Cta location={this.props.location} />

        {visible && (
          <ModalExample
            visible={visible}
            Industry="automotive"
            closeHandler={this.linkClickHandler}
            gaTrackLabel="Automotive page"
          />
        )}

        {pricingVisible && (
          <ModalPricingRequest
            onClose={() => this.setState({ pricingVisible: !pricingVisible })}
          />
        )}
      </Layout>
    );
  }
}
